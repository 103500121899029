const config = {
  localhost: {
    url: "http://localhost:3001/",
    apiUrl: "http://localhost:5000/dev/",
    eventApiUrl: "http://localhost:5000/dev/",
    // eventApiUrl: "https://zq9xs0zsd2.execute-api.us-west-2.amazonaws.com/dev/",
    keyId: process.env.REACT_APP_KEY_ID,
    accessKey: process.env.REACT_APP_SECRET_KEY,
    s3_url: "https://dev-truekarma-images.s3.us-west-2.amazonaws.com/",
    eventBucketName: "dev-event-truekarma-image",
    bucketName: "dev-truekarma-images",
    region: "us-west-2",
    username:"adminLocalUser"
  },
  develop: {
    event_url: "https://develop.d1c7gs4gh1aeoc.amplifyapp.com/",
    url: "https://admin-events.trukarma.org/",
    apiUrl: "https://ujkc1bts7i.execute-api.us-west-2.amazonaws.com/dev/",
    eventApiUrl: "https://zq9xs0zsd2.execute-api.us-west-2.amazonaws.com/dev/",
    // apiUrl: "https://localhost:7000/dev/",
    // eventApiUrl: "http://localhost:8000/dev/",
    keyId: process.env.REACT_APP_KEY_ID,
    accessKey: process.env.REACT_APP_SECRET_KEY,
    s3_url: "https://dev-truekarma-images.s3.us-west-2.amazonaws.com/",
    eventBucketName: "dev-event-truekarma-image",
    bucketName: "dev-truekarma-images",
    region: "us-west-2",
    username:"adminDevUser"
  },
  staging: {
    event_url: "https://staging.trukarma.org/",
    url: "https://admin-events-staging.trukarma.org/",
    adminUrl: "https://staging.d3aj6u8sdhvo16.amplifyapp.com/",
    apiUrl: "https://lowkl3bgqd.execute-api.us-east-2.amazonaws.com/staging/",
    eventApiUrl:
    "https://3s5w3uc7v2.execute-api.us-east-2.amazonaws.com/staging/",
    s3_url: "https://karma-images.s3.us-east-2.amazonaws.com/",
    eventBucketName: "staging-event-trukakarma-image",
    keyId: process.env.REACT_APP_KEY_ID,
    accessKey: process.env.REACT_APP_SECRET_KEY,
    bucketName: "karma-images",
    region: "us-east-2",
    username:"adminStageUser"
  },
  production: {
    url: "https://admin-events.truekarma.org",
    event_url: "https://truekarma.org",
    apiUrl: "https://7kzwzxeut3.execute-api.us-east-1.amazonaws.com/prod/",
    eventApiUrl: "https://m1phg54oi7.execute-api.us-east-1.amazonaws.com/prod/",
    s3_url: "https://truekarma-images.s3.amazonaws.com/",
    eventBucketName: "event-truekarma-image",
    keyId: process.env.REACT_APP_KEY_ID,
    accessKey: process.env.REACT_APP_SECRET_KEY,
    bucketName: "truekarma-images",
    region: "us-east-1",
    username: "adminProdUser"
  },
};

const environment = "production";

const hostConfig = {
  WEB_URL: config[environment].url, 
  EVENT_WEB_URL: config[environment].event_url,
  IMAGE_URL: `https://${config[environment].bucketName}.s3.amazonaws.com`,
  API_URL: config[environment].apiUrl,
  EVENTS_API_URL: config[environment].events_apiUrl,
  ACCESS_KEY_ID: `${config[environment].keyId}`,
  SECRET_ACCESS_KEY: `${config[environment].accessKey}`,
  AWS_REGION: `${config[environment]?.region}`,
  EVENT_API_URL: config[environment].eventApiUrl,
  TRUEKARMA_S3_URL: `${config[environment].s3_url}`,
  S3_BUCKET: `${config[environment].bucketName}`,
  EVENT_S3_BUCKET: `${config[environment].eventBucketName}`,
  EVENT_S3_URL: `https://${config[environment].eventBucketName}.s3.amazonaws.com/events/`,
  USERNAME: `${config[environment].username}`,
  // S3_URL: `${config[environment].s3_url}`,
  reload: 1500,
  intervals: 500,
};

module.exports = {
  hostConfig,
};
