import React, { useState } from "react";

//***************************Import react packages ************************ */
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";

//***************************Import react Icons ************************ */
import TrueKarmaLogo from "../../assets/logo-white.svg";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { loginUser } from "../../api/others";
//***************************Import components ************************ */
import "./login.css";
import Input from "../../components/form-control/login-input/input";
import Input1 from "../../components/form-control/login-input/input1";
import { saveLocalUser } from "../../utils/auth";

function Login() {
  // const [setLoginUsers] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const history = useHistory();

  const handleButtonLoader = () => setButtonLoader(true);

  const initialValues = {
    email: "",
    password: "",
  };

  const ErrorIcon = () => <HiOutlineExclamationCircle className="error-icon" />;

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required(
        <span>
          <ErrorIcon /> Email is required
        </span>
      )
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email"
      ),
    password: Yup.string().required(
      <span>
        <ErrorIcon /> Password is required
      </span>
    ),
  });

  const onSubmit = async (values) => {
    values.roll = "admin"
    setButtonLoader(true);
   await loginUser(values).then(res => {
        if (res?.message === "Logged in successfully") {
          toast.success("Login Successful");
          history.push("/home/dashboard");
          saveLocalUser(res?.data);
          // localStorage.setItem("user", res?.data);
          setButtonLoader(false);
        } else {
          toast.error("Login Unsuccessful");
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("Login Unsuccessful");
          setButtonLoader(false);
        }
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });
  return (
    <div className="LoginPicture">
      <div className="Login_shade">
        <section className="d-flex justify-content-center ">
          <div className="TruekarmaLogo  mt-5">
            <img
              src={TrueKarmaLogo}
              alt="TrukarmaLogo"
              width="150px"
              height="150px"
            />
          </div>
        </section>
        <div className="d-flex justify-content-center">
          <div className="login_box ">
            <div className="Login_header">Login</div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div>
                  <Input
                    name="email"
                    className="LoginInput"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="User email"
                  />
                  <div className="error-space_records">
                    {formik.errors.email && formik.touched.email && (
                      <div className="error_txt_records">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-5 pb-5">
                  <Input1
                    name="password"
                    className="LoginInput"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Password"
                  />

                  <div className="error-space_records">
                    {formik.errors.password && formik.touched.password && (
                      <div className="error_txt_records">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className={`  ${
                    buttonLoader
                      ? `Login_btn mb-4 btn btn-warning btn-block`
                      : `Login_btn mb-4 btn btn-warning btn-block`
                  }`}
                  disabled={buttonLoader}
                  onClick={buttonLoader ? handleButtonLoader : null}
                >
                  {buttonLoader ? (
                    <span className="d-flex">
                      {" "}
                      <span style={{ paddingLeft: "40%" }}>
                        <CircleSpinner size={18} color="#fff" />
                      </span>{" "}
                      <span style={{ paddingLeft: "10px", color: "white" }}>
                        Login{" "}
                      </span>
                    </span>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="Login_footer pb-5">© 2019, TrueKarma.</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
