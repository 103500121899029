// Here we will create dark and light theme variables

export const lightTheme = {
  body: "#FCF6F4",
  text: "#000000",
  fontFamily: "open sans, sans-serif",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};

export const DarkTheme = {
  body: "#000000",
  text: "#FCF6F4",
  fontFamily: "open sans, sans-serif",
  textRgba: "252, 246, 244",
  bodyRgba: "0,0,0",
};
const theme = {
  colors: {
    plum: "#52314b",
    rose: "#c3537d",
    dryrose: "#dd7f9a",
    primrose: "#e8babf",
    white: "#fff",
    dark: "black",
    gray: "#3c4b64",
    GrayTop: "#303c54",
  },
};

export default theme;
