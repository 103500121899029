/** **************************** Import Types ****************************** */
import {
    ENQUIRES_SUCCESS,
    ENQUIRES_REQUEST,
    ENQUIRES_FAILURE,
    ENQUIRES_TOTAL_ENQUIRIES,
} from "../types/enquiries.types";

const initialState = {
    enquireResponse: "",
    totalViewedCount : "",
    error: "",
    loading: false,
};
export const enquiriesReducer = (state = initialState, action) => {

    switch (action.type) {
        case  ENQUIRES_REQUEST:
          
            return {
              
                ...state,
                enquireResponse: "",
                loading: true,
            };
        case ENQUIRES_SUCCESS:
            
            return {
                ...state,
                enquireResponse: action.payload,                
                loading: false,
            };
            case ENQUIRES_TOTAL_ENQUIRIES:
            
            return {
                ...state,
                totalViewedCount: action.payload,                
                loading: false,
            };
            
        case ENQUIRES_FAILURE:
          
            return {
                enquireResponse: "error",
                error: action.payload,
                loading: false,
            };
        default:
          
            return state;
    }
};
// export default enquiriesReducer;
