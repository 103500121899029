/** **************************** Import Types ****************************** */
import {
    NOTIFICATION_FAILURE,
    NOTIFICATION_REQUEST,
    NOTIFICATION_TOTAL_ENQUIRIES,
} from "../types/notification.types";

const initialState = {
    notificationResponse: "",
    error: "",
    loading: false,
};
export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case  NOTIFICATION_REQUEST:
          
            return {
              
                ...state,
                notificationResponse: "",
                loading: true,
            };
        case NOTIFICATION_TOTAL_ENQUIRIES:
            
            return {
                ...state,
                notificationResponse: action.payload,                
                loading: false,
            };
            
        case NOTIFICATION_FAILURE:
          
            return {
                notificationResponse: "error",
                error: action.payload,
                loading: false,
            };
        default:
          
            return state;
    }
};
// export default enquiriesReducer;
