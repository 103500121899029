import { postDataApi, getListByApi, viewDataByApi } from "./actions";
import { OTHER_CONSTANTS } from "./urls";

const checkLogin = params => {
  return postDataApi(OTHER_CONSTANTS.login,params);
}

const loginUser = params => {
  return postDataApi(OTHER_CONSTANTS.login,params);
}

const recoverPasswordByEmail = params => {
  return postDataApi(OTHER_CONSTANTS.recoverpassword,params);
}

const checkUserExist = params => {
  return postDataApi(OTHER_CONSTANTS.checkuser,params);
}

const verifyEmailByCode = params => {
  return viewDataByApi(OTHER_CONSTANTS.verify,params);
}

const getReportsList = (params,type) => {
  const url = OTHER_CONSTANTS.reports+"/"+type;
  return getListByApi(url, params);
}

const sendVerifiyCodeByUser = params => {
  return viewDataByApi(OTHER_CONSTANTS.sendcode,params);
}

const fetchUrlData = params => {
  return postDataApi(OTHER_CONSTANTS.fetch_data,params);
}

const resetStellar = params => {
  return postDataApi(OTHER_CONSTANTS.recreate_stellar,params);
}

const resetUsers = params => {
  return postDataApi(OTHER_CONSTANTS.recreate_users,params);
}

const resetLogs = params => {
  return postDataApi(OTHER_CONSTANTS.recreate_logs,params);
}

const getToken = () => {
  const params = {};
  params.domain = "https://admin.truekarma.org";
  return postDataApi(OTHER_CONSTANTS.get_token, params);
}

export {
  checkLogin,
  checkUserExist,
  loginUser,
  recoverPasswordByEmail,
  verifyEmailByCode,
  getReportsList,
  sendVerifiyCodeByUser,
  fetchUrlData,
  getToken,
  resetStellar,
  resetUsers,
  resetLogs
}