/** **************************** Import Libs ****************************** */
import { combineReducers } from "redux";
/** **************************** Import Reducers ****************************** */
import { sideBarReducer } from "./reducers/sidebar.reducer";
import { NavBarReducer } from "./reducers/header-nav.reducer";
import { profileDropDownReducer } from "./reducers/profile-dropdown.reducer";
import { BreadCrumbReducer } from "./reducers/breadcrumb.reducer";
import { enquiriesReducer } from "./reducers/enquiries.reducers";
import { notificationReducer } from "./reducers/notification.reducers";
import { fundraiserWithdrawalReducer } from "./reducers/campaign-withdraw.reducer";

const rootReducer = combineReducers({
  SideBar: sideBarReducer,
  NavbarReduce: NavBarReducer,
  profileDropdown: profileDropDownReducer,
  breadCrumb: BreadCrumbReducer,
  Enquires: enquiriesReducer,
  Notification: notificationReducer,
  Fundraiser: fundraiserWithdrawalReducer,
});

export default rootReducer;
