/** **************************** Import Types ****************************** */
import {
    FUNDRAISER_FAILURE,
    FUNDRAISER_REQUEST,
    FUNDRAISER_TOTAL_WITHDRAWAL_COUNT,
} from "../types/campaign-withdraw.types";

const initialState = {
    notificationResponse: "",
    error: "",
    loading: false,
};
export const fundraiserWithdrawalReducer = (state = initialState, action) => {
    switch (action.type) {
        case  FUNDRAISER_REQUEST:
          
            return {
              
                ...state,
                notificationResponse: "",
                loading: true,
            };
        case FUNDRAISER_TOTAL_WITHDRAWAL_COUNT:
            
            return {
                ...state,
                notificationResponse: action.payload,                
                loading: false,
            };
            
        case FUNDRAISER_FAILURE:
          
            return {
                notificationResponse: "error",
                error: action.payload,
                loading: false,
            };
        default:
          
            return state;
    }
};
// export default enquiriesReducer;
